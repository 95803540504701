import {
  computed,
  useRouter,
  useRoute,
  useStore,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';

import { useStorage } from '@vueuse/core';
import debounce from 'lodash.debounce';
import {
  LOGIN,
  REGISTRATION,
  SUCCESS,
  AUTH_PAGE_ROUTE_NAME,
  PROMO_CODE,
  AUTH_ISSUE_ID_KEY,
  TINKOFF_SUCCESS_LOGIN,
  TINKOFF_ERROR_LOGIN,
} from '@/utils/constants/authorizationConstants';
import { LOCAL_CONST_CUSTOMERS } from '@/utils/constants/globalConst';
import { analyticAuthFormOpen, analyticAuthFormSubmitSuccess } from '@/utils/analytics/analyticEvents';

import useCommonModal from '@/compositions/useCommonModal';
import useNotifications from '@/compositions/useNotifications';
import { useAuthorizationContext } from '@/compositions/authorization/authorizationStorage';
import { useBasketContext } from '@/compositions/basket/basketStorage';
import getOptionsSetCookie from '@/utils/getOptionsSetCookie';
import useHttpClient from '@/compositions/useHttpClient';

const useAuthorization = () => {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const { createCustomersAndBasket, fetchBasket, basketId } = useBasketContext();
  const authorizationContext = useAuthorizationContext();
  const notifications = useNotifications();
  const { $cookies, $config } = useContext();
  const { product } = useHttpClient();

  const tinkoffBlocked = $cookies.get(TINKOFF_ERROR_LOGIN);
  const tinkoffSuccess = $cookies.get(TINKOFF_SUCCESS_LOGIN);
  const visitorId = $cookies.get('BITRIX_SM_book24_visitor_id');
  const authIssueId = useStorage(AUTH_ISSUE_ID_KEY, '');
  const customerId = useStorage(LOCAL_CONST_CUSTOMERS, '');

  const mode = computed(() => authorizationContext.mode.value);
  const isLogin = computed(() => mode.value === LOGIN);
  const isRegistration = computed(() => mode.value === REGISTRATION);
  const isSuccess = computed(() => mode.value === SUCCESS);
  const isPromoCode = computed(() => mode.value === PROMO_CODE);
  const isCheckout = computed(() => store.state?.checkout);
  const isAuthPage = computed(() => route.value.name === AUTH_PAGE_ROUTE_NAME);
  const isAuthByTinkoffId = computed(() => store.state.siteSettings.authByTinkoffIdEnable && !tinkoffBlocked);
  const tinkoffHasError = computed(() => tinkoffBlocked);
  const hasRedirect = computed(() => route.value.query.redirect);

  const updateAuthData = (data) => {
    if (!data) {
      authIssueId.value = null;
      $cookies.remove(AUTH_ISSUE_ID_KEY);
      return;
    }
    authIssueId.value = data.id;
    $cookies.set(AUTH_ISSUE_ID_KEY, data.id, getOptionsSetCookie($config.domainCookie));
  };

  const changeModeHandler = (value) => {
    authorizationContext.mode.value = value;
  };

  const { showAuth, hideModalAuth } = useCommonModal();
  const openAuthorizationModal = debounce((entryPoint) => {
    changeModeHandler(LOGIN);
    showAuth();
    analyticAuthFormOpen(route.value, entryPoint);
  }, 1000, { leading: true, trailing: false });

  const closeAuthorizationModal = debounce(() => {
    hideModalAuth();
  }, 1000, { leading: true, trailing: false });

  const authorizationHandler = async () => {
    analyticAuthFormSubmitSuccess(LOGIN);
    localStorage.removeItem(AUTH_ISSUE_ID_KEY);
    $cookies.remove(AUTH_ISSUE_ID_KEY);
    $cookies.remove(TINKOFF_SUCCESS_LOGIN);
    $cookies.remove(TINKOFF_ERROR_LOGIN);
    await store.dispatch('setIsAuthorized', true);

    // Нужно ли редиректить на Главную после авторизации
    const redirectToMain = (isAuthPage.value) && !hasRedirect.value;
    if (redirectToMain) await router.push('/');

    // Если Checkout после авторизации получаем данные заказа
    if (isCheckout.value) {
      await store.dispatch('checkout/getUserSession');
      await createCustomersAndBasket(true);
      await store.dispatch('checkout/setBasketId', basketId.value);
      await store.dispatch('main/forceRenderNuxt');
    } else {
      await createCustomersAndBasket(true);
      await fetchBasket();
      await store.dispatch('main/forceRenderNuxt');
    }
  };

  onMounted(async () => {
    if ($cookies.get(TINKOFF_SUCCESS_LOGIN)) {
      analyticAuthFormSubmitSuccess(LOGIN, true);
      await authorizationHandler();

      const promoCode = await product.getCouponForUser();
      if (promoCode) {
        store.commit('setPromoCode', promoCode);
        changeModeHandler(PROMO_CODE);
        showAuth();
      }
    }
  });

  return {
    ...notifications,
    mode,
    basketId,
    visitorId,
    authIssueId,
    customerId,
    isLogin,
    isRegistration,
    isSuccess,
    isPromoCode,
    isCheckout,
    isAuthByTinkoffId,
    openAuthorizationModal,
    closeAuthorizationModal,
    tinkoffHasError,
    tinkoffSuccess,
    updateAuthData,
    authorizationHandler,
    changeModeHandler,
  };
};

export default useAuthorization;
